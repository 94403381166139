import moment from 'moment';

export const localizeDate = (date) => {
  return moment.utc(date).local();
};

export const getTimeRemaining = (startDate) => {
  const now = moment();
  const _startDate = moment.utc(startDate);
  return _startDate.diff(now, 'minutes');
};
