import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { USER_STATUS_CREATED, USER_STATUS_IN_ACCOMPANIMENT_REQUEST } from 'utils/statuses';

import { getStatusSelector, getTokenSelector } from '../state/auth';

export default function ProtectedRouteManager({ children }) {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const status = useRecoilValue(getStatusSelector);

  // RRD
  const location = useLocation();
  const navigate = useNavigate();

  // Local vars
  const isRequestPath = location.pathname.includes('/app/request');

  // Handle user status navigation
  useEffect(() => {
    if (
      !isRequestPath &&
      [USER_STATUS_CREATED, USER_STATUS_IN_ACCOMPANIMENT_REQUEST].includes(status)
    ) {
      navigate('/app/request');
    }
  }, [isRequestPath, navigate, status]);

  // Redirect if not authenticated
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

ProtectedRouteManager.propTypes = {
  children: PropTypes.any.isRequired,
};
