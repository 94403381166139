import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Content from 'components/Content';
import Header from 'components/Header';
import DailyPhrase from 'components/Home/DailyPhrase';
import Loader from 'components/Loader';
import ProfileCard from 'components/ProfileCard';
import Subtitle from 'components/Subtitle';
import React, { Suspense } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDetail as getAccompanimentDetail } from 'services/accompaniment';
import { retrieveDailyPhrase } from 'services/generics';
import { accompanimentState } from 'state/accompaniment';
import { getTokenSelector, getUserSelector } from 'state/auth';
import { selectedSession as selectedSessionState } from 'state/session';
import { getUserFriendlyCronExpression } from 'utils/cron';
import { ACCOMPANIMENT_STATUSES } from 'utils/parsers/acompaniment';
import {
  ACCOMPANIMENT_ACCEPTED,
  ACCOMPANIMENT_CANCELED_BY_ADMIN,
  ACCOMPANIMENT_CANCELED_BY_PATIENT,
  ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_CANCELED_NO_PAYMENT,
  ACCOMPANIMENT_FINALIZED,
  ACCOMPANIMENT_IN_PROGRESS,
  ACCOMPANIMENT_STOPPED_BY_PATIENT,
  ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_STOPPED_NO_PAYMENT,
  USER_STATUS_CREATED,
  USER_STATUS_NO_ACTIVITIES,
} from 'utils/statuses';

import Canceled from './statuses/canceled';
import Finalized from './statuses/finalized';
import InProgress from './statuses/inProgress';
import NoPayment from './statuses/noPayment';
import Stopped from './statuses/stopped';

const componentsByStatus = {
  [ACCOMPANIMENT_ACCEPTED]: InProgress,
  [ACCOMPANIMENT_IN_PROGRESS]: InProgress,
  [ACCOMPANIMENT_STOPPED_NO_PAYMENT]: NoPayment,
  [ACCOMPANIMENT_STOPPED_BY_PATIENT]: Stopped,
  [ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST]: Stopped,
  [ACCOMPANIMENT_CANCELED_NO_PAYMENT]: Canceled,
  [ACCOMPANIMENT_CANCELED_BY_PATIENT]: Canceled,
  [ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST]: Canceled,
  [ACCOMPANIMENT_CANCELED_BY_ADMIN]: Canceled,
  [ACCOMPANIMENT_FINALIZED]: Finalized,
};

export default function Home() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const user = useRecoilValue(getUserSelector);
  const [, setSelectedSession] = useRecoilState(selectedSessionState);
  const [, setAccompaniment] = useRecoilState(accompanimentState);

  const noActivities = [USER_STATUS_NO_ACTIVITIES, USER_STATUS_CREATED].includes(user?.status);

  // RRD
  const navigate = useNavigate();

  // RQ
  const retrieveDailyPhraseQuery = useQuery(
    ['retrieveDailyPhrase', token],
    () => retrieveDailyPhrase(token),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { data: accompaniment, isLoading: isLoadingAccompaniment } = useQuery(
    ['accompanimentDetail', token],
    () => getAccompanimentDetail(token),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !noActivities,
      onSuccess: (data) => {
        setAccompaniment(data);
      },
    },
  );

  const onClickSessionHandler = (session) => {
    setSelectedSession(session);
    navigate('sessions/detail');
  };

  if (noActivities) {
    return (
      <BasicLayout>
        <Content>
          <strong>¿Quieres terapia de pareja?</strong> ¡No hay problema!. Crea tu perfil personal y
          preséntate en la cita con tu pareja.
        </Content>
      </BasicLayout>
    );
  }

  if (accompaniment) {
    const inProgress = [ACCOMPANIMENT_ACCEPTED, ACCOMPANIMENT_IN_PROGRESS].includes(
      accompaniment.status,
    );
    // const userFriendlyCronExpression = getUserFriendlyCronExpression(accompaniment.periodicity);

    const StatusComponent = componentsByStatus[String(accompaniment.status)];
    const { text: statusAsText, color: colorStatus } = ACCOMPANIMENT_STATUSES[accompaniment.status];
    let isStopped = [
      ACCOMPANIMENT_STOPPED_BY_PATIENT,
      ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
    ].includes(accompaniment.status);

    let dynamicProps = {};
    if ([ACCOMPANIMENT_ACCEPTED, ACCOMPANIMENT_IN_PROGRESS].includes(accompaniment.status)) {
      dynamicProps = {
        title: accompaniment.title,
        description: accompaniment.description,
        nextSession: accompaniment.next_session,
        onClickCallback: onClickSessionHandler,
      };
    } else if (isStopped) {
      dynamicProps = {
        // fix this ->
        pausedAt: accompaniment.next_session.start_date,
      };
    }

    return (
      <BasicLayout>
        <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
          <Header title={accompaniment?.title} description={accompaniment?.description}>
            <div className="col-span-1 mt-2">
              <div
                className={`w-1/3 lg:w-full p-2 text-center rounded-md ${colorStatus} text-white`}
              >
                {statusAsText}
              </div>
            </div>
          </Header>

          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
            {/* CONTENT */}
            <div className="col-span-1 ">
              {/* <div className="col-span-1 mb-8">
                <Content className="text-content text-gray-500 text-xl">
                  Tus sesiones serán{' '}
                  <span className="text-xl text-gray-800">{userFriendlyCronExpression[2]}es</span>{' '}
                  los días{' '}
                  <span className="text-xl text-gray-800">{userFriendlyCronExpression[4]}</span>{' '}
                </Content>{' '}
              </div> */}

              <div className="grid grid-cols-1 lg:gap-x-4">
                <Suspense fallback={<p>Algo salió mal</p>}>
                  <StatusComponent {...dynamicProps} />
                </Suspense>
                <br />
                {/* TODO: MAKE A COMPONENT */}
                {accompaniment.psychologist && inProgress ? (
                  <div className="mt-8 lg:mt-0">
                    <div className="rounded-full">
                      <Subtitle className="text-title text-gray-500">Dover</Subtitle>
                    </div>

                    <div className="grid rounded-md cursor-pointer">
                      <Button onClick={() => navigate('accompaniment/dover')}>
                        <ProfileCard
                          image={accompaniment.psychologist?.photo_URL}
                          title={`${accompaniment.psychologist?.first_name} ${accompaniment.psychologist?.last_name}`}
                        />
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {retrieveDailyPhraseQuery.isSuccess ? (
              <div className="mt-8 lg:mt-0">
                <DailyPhrase
                  content={retrieveDailyPhraseQuery.data.content}
                  category={retrieveDailyPhraseQuery.data.category}
                  author={retrieveDailyPhraseQuery.data.author}
                />
              </div>
            ) : null}
          </div>
        </div>
      </BasicLayout>
    );
  }

  if (!user || isLoadingAccompaniment || retrieveDailyPhraseQuery.isLoading) {
    <div className="grid grid-cols-3">
      <div className="col-span-1 col-start-2 flex justify-center mt-8">
        <Loader className="h-12 w-12 text-secondary" />
      </div>
    </div>;
  }

  return <></>;
}
