import './index.css';

import App from 'App';
import Accompaniment from 'pages/Accompaniment';
import AccompanimentDover from 'pages/Accompaniment/Dover';
import AccompanimentICChat from 'pages/Accompaniment/ICChat';
import AccompanimentRequest from 'pages/AccompanimentRequest';
import ACRContract from 'pages/AccompanimentRequest/Contract';
import ACRPay from 'pages/AccompanimentRequest/Payment';
import ACRPaymentMethod from 'pages/AccompanimentRequest/PaymentMethod';
import ACRProfile from 'pages/AccompanimentRequest/Profile';
import ACRPsychologistsContainer from 'pages/AccompanimentRequest/Psychologists';
import ACRPsychologistAvailabilities from 'pages/AccompanimentRequest/Psychologists/availabilities';
import ACRPsychologistDetail from 'pages/AccompanimentRequest/Psychologists/detail';
import ACRPsychologists from 'pages/AccompanimentRequest/Psychologists/list';
import ACRTags from 'pages/AccompanimentRequest/Tags';
import Autodiagnosis from 'pages/Autodiagnosis';
import AutodiagnosisAbout from 'pages/Autodiagnosis/About';
import AutodiagnosisFlow from 'pages/Autodiagnosis/Flow';
import CustomQueryClientProvider from 'pages/CustomQueryClientProvider';
import Home from 'pages/Home';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import ProfilePaymentMethods from 'pages/PaymentMethods';
import Profile from 'pages/Profile';
import ProfileDetail from 'pages/Profile/Detail';
import ProfileUpdate from 'pages/Profile/Update';
import Sessions from 'pages/Sessions';
import SessionsDetail from 'pages/Sessions/Detail';
import SessionCancel from 'pages/Sessions/Detail/actions/cancel';
import SessionReschedule from 'pages/Sessions/Detail/actions/reschedule';
import SessionTickets from 'pages/Sessions/Detail/actions/tickets';
import SessionsList from 'pages/Sessions/List';
import Signup from 'pages/Signup';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Toaster } from 'react-hot-toast';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import reportWebVitals from 'reportWebVitals';
import ProtectedRouteManager from 'wrappers/ProtectedRouteManager';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

if (process.env.REACT_APP_IS_PRODUCTION) {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <CustomQueryClientProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="app"
              element={
                <ProtectedRouteManager>
                  <App />
                </ProtectedRouteManager>
              }
            >
              <Route index element={<Home />} />
              <Route path="accompaniment" element={<Accompaniment />}>
                <Route path="dover" element={<AccompanimentDover />} />
              </Route>
              {/* NEW */}
              <Route path="request" element={<AccompanimentRequest />}>
                <Route index element={<ACRProfile />} />
                <Route path="tags" element={<ACRTags />} />
                <Route path="dover" element={<ACRPsychologistsContainer />}>
                  <Route index element={<ACRPsychologists />} />
                  <Route path=":id" element={<ACRPsychologistDetail />} />
                  <Route path=":id/availabilities" element={<ACRPsychologistAvailabilities />} />
                </Route>
                <Route path="contract" element={<ACRContract />} />
                <Route path="paymentMethod" element={<ACRPaymentMethod />} />
                <Route path="pay" element={<ACRPay />} />
                {/* <Route path="autodiagnosis" element={<ACRAutodiagnosis />} /> */}
              </Route>

              <Route path="sessions" element={<Sessions />}>
                <Route index element={<SessionsList />} />
                <Route path="detail" element={<SessionsDetail />}>
                  <Route path="cancel" element={<SessionCancel />} />
                  <Route path="reschedule" element={<SessionReschedule />} />
                  <Route path="review" element={<SessionCancel />} />
                  <Route path="tickets" element={<SessionTickets />} />
                </Route>
              </Route>
              <Route path="profile" element={<Profile />}>
                <Route index element={<ProfileDetail />} />
                <Route path="update" element={<ProfileUpdate />} />
              </Route>
              <Route path="chat" element={<AccompanimentICChat />} />
              <Route path="payment-methods" element={<ProfilePaymentMethods />} />
              <Route path="autodiagnosis" element={<Autodiagnosis />}>
                <Route path="about" element={<AutodiagnosisAbout />} />
                <Route path="flow" element={<AutodiagnosisFlow />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </CustomQueryClientProvider>
    </RecoilRoot>
    <Toaster
      position="top-right"
      toastOptions={{
        // Define default options
        className: '',
        duration: 3000,
        style: {
          background: '#eaeaea',
          color: '#111827',
        },
      }}
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
