import { Transition } from '@headlessui/react';
import { ArrowRightIcon, SpeakerphoneIcon } from '@heroicons/react/outline';
import Alert from 'components/Alert';
import PropTypes from 'prop-types';
import React from 'react';

export default function PriceAlert({ hasAccompaniment, alertCallback }) {
  return (
    <Transition
      show={!hasAccompaniment}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Alert
        type="info"
        text={
          <>
            Consulta nuestros precios{' '}
            <a
              href={'https://mindove.org/precio/'}
              className="font-bold cursor-pointer hover:text-secondary"
            >
              aquí
            </a>
          </>
        }
        showActionIcon={false}
        Icon={SpeakerphoneIcon}
        ActionIcon={ArrowRightIcon}
        actionCallback={alertCallback}
      />
    </Transition>
  );
}

PriceAlert.propTypes = {
  hasAccompaniment: PropTypes.object,
  alertCallback: PropTypes.func,
};

PriceAlert.defaultProps = {
  alertCallback: () => null,
};
