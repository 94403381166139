import axios from 'axios';

import { API_URL } from '../utils/constants';
import { headers } from '../utils/headers';

const COMPANIES_PATH = 'company/';

export const verifyCompanyUser = async (token) => {
  const { data } = await axios.get(`${API_URL}${COMPANIES_PATH}verify-user/`, {
    headers: headers(token),
  });

  return data;
};
