import 'moment-timezone';

import BackButton from 'components/BackButton';
import Loader from 'components/Loader';
import PublicProfileExperiences from 'components/Psychologist/PublicProfileExperiences';
import PublicProfileCard from 'components/PublicProfileCard';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getPublicDetail as getPsychologistPublicDetail } from 'services/psychologist';
import { getTokenSelector } from 'state/auth';
import { PSYCHOLOGIST_DEGREES } from 'utils/constants';
import { TYPE_EDUCATION, TYPE_EXPERIENCE } from 'utils/constants/experiences';
import { TYPE_CONDITION, TYPE_OCCUPATION } from 'utils/constants/tags';

export default function PsychologistDetail() {
  // Global state
  const token = useRecoilValue(getTokenSelector);

  // RRD
  const navigate = useNavigate();
  const { id: psychologistId } = useParams();

  const { data: psychologist } = useQuery(
    ['getPsychologistPublicDetail', token, psychologistId],
    () => getPsychologistPublicDetail(token, psychologistId),
    {
      refetchOnWindowFocus: false,
      enabled: !!psychologistId,
    },
  );

  const buildTag = ({ id, text, color }) => (
    <div key={id}>
      <Tag id={id} tag={text} selected color={color} onClickCallback={() => true} withX={false} />
    </div>
  );

  if (psychologist) {
    const photoURL = psychologist?.photo_URL;
    const video = psychologist?.video;
    const name = `${psychologist?.first_name} ${psychologist?.last_name}`;
    const degree = PSYCHOLOGIST_DEGREES[`${psychologist?.degree}`].degree;
    const degreeColor = PSYCHOLOGIST_DEGREES[`${psychologist?.degree}`].color;
    const degreeCost = PSYCHOLOGIST_DEGREES[`${psychologist?.degree}`].sessionCost;
    const biography = psychologist?.biography;
    const location = psychologist?.address?.country?.name;
    const now = moment().tz(psychologist?.address?.timezone?.name);
    const datetime = now.format('MMMM Do YYYY, h:mm:ss a');
    const reason = psychologist?.reason;
    const education = psychologist?.educations;
    const experience = psychologist?.experiences;
    const occupationTags = psychologist.tags.filter(({ type }) => type === TYPE_OCCUPATION);
    const conditionTags = psychologist.tags.filter(({ type }) => type === TYPE_CONDITION);
    return (
      <>
        <div className="col-span-1 md:col-start-2 px-4 w-20 ">
          <BackButton onClick={() => navigate(-1)} />
        </div>

        <div className="sm:col-span-1 md:col-span-3 md:col-start-2 p-4">
          <PublicProfileCard
            id={psychologist?.id}
            photoURL={photoURL}
            name={name}
            video={video}
            title={degree}
            titleColor={degreeColor}
            degreeCost={degreeCost}
            biography={biography}
            location={location}
            datetime={datetime}
            reason={reason}
            onClick={() => navigate('availabilities')}
          />
        </div>

        <div className="sm:col-span-1 md:col-span-3 p-4">
          {/* <div className="col-span-1 mb-4">
            <Subtitle className="text-subtitle text-sm font-bold text-gray-500 mb-2">
              Se especializa en ayuda a:
            </Subtitle>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
              {occupationTags.map(buildTag)}
            </div>
          </div> */}
          <div className="col-span-1 mb-4">
            <Subtitle className="text-subtitle text-sm font-bold text-gray-500 mb-2">
              Es un experto en:
            </Subtitle>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
              {conditionTags.map(buildTag)}
            </div>
          </div>
          <div className="col-span-1" key="experiences">
            <Subtitle className="text-subtitle text-sm font-bold text-gray-500 mb-4">
              Educación
            </Subtitle>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4">
              <PublicProfileExperiences id={0} type={TYPE_EXPERIENCE} items={education} />
            </div>
          </div>
          <br />
          <div className="col-span-1" key="educations">
            <Subtitle className="text-subtitle text-sm font-bold text-gray-500 mb-4">
              Experiencia
            </Subtitle>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2">
              <PublicProfileExperiences id={1} type={TYPE_EDUCATION} items={experience} />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full flex justify-center mt-2">
      <Loader className="h-10 w-10 text-primary" />
    </div>
  );
}
