import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Content from 'components/Content';
import Input from 'components/Input';
import Loader from 'components/Loader';
import TextArea from 'components/TextArea';
// import Subtitle from 'components/Subtitle';
import Title from 'components/Title';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ActionCancelSessionSchema } from 'schemas/actions';
import { getCancelationStatus } from 'services/session';
import { createSessionCancel as createSessionCancelTicket } from 'services/ticket';
import { getTokenSelector } from 'state/auth';
import { selectedSession } from 'state/session';

const TYPE_1 = 1;
const TYPE_2 = 2;
const contentByCancelType = {
  [TYPE_1]: (
    <>
      <ul className="list-inside list-disc">
        <li className="mb-2">
          Nos da mucha pena que quieras cancelar tu cita, al hacerlo en el rango de tiempo que hemos
          acordado en el contrato terapéutico, podremos reembolsarte.
        </li>
        <li className="mb-2">
          Te invitamos a que antes de cancelar, verifiques con tu Psicólogo Cather si te puede
          ubicar en un horario diferente solo por esta cita, de esta manera no afectaras tu proceso
          de acompañamiento.
        </li>
        <li className="mb-2">
          En el caso de que acuerdes un nuevo horario con tu Psicólogo Cather para esta cita, no es
          necesario realizar un reembolso.
        </li>
        <li className="mb-2">
          En el caso de que tu Psicólogo Cather o tu no encuentren un nuevo horario, el reembolso
          procederá.
        </li>
        <li className="mb-2">
          Solo el orden, la constancia y confianza en tu terapeuta te ayudarán a salir adelante, te
          invitamos a que no canceles y acudas a tu cita.
        </li>
      </ul>
      <Content className="text-md text-gray-400 leading-5">
        ***Todas las cancelaciones se contabilizarán, en cuanto se acumule un total de 3
        cancelaciones, la Terapia Humanista Integral será cancelado.
      </Content>
    </>
  ),
  [TYPE_2]: (
    <>
      <Content>
        Nos da mucha pena que quieras cancelar tu cita, recuerda que al no hacerlo en el rango de
        tiempo estipulado en el contrato terapéutico, no podemos reembolsarte. Para poder hacer
        reembolsos, las citas se deben de cancelar o reagendar con por lo menos{' '}
        <strong>4 días de anticipación</strong>.
        <br />
        <br />
        Si deseas solicitar una cita de reemplazo antes de tu próxima cita programada, dependerá que
        el Psicólogo Cather tenga disponibilidad en su agenda.
        <br />
        <br />
        Por tal motivo el Psicólogo Cather tiene la libertad de decidir si acepta o rechaza la
        solicitud de reagendar conforme a sus tiempos, ya que es probable que tu no seas su único
        paciente.
        <br />
        <br />
        <ul className="list-inside list-disc">
          <li className="mb-2">
            En caso de ser aceptado, se te proporcionará un nuevo horario y te lo hará llegar tu
            Psicólogo Cather y adicional a ello se pagará como una cita adicional dado a la que ya
            tienes programada.
          </li>
          <li className="mb-2">
            En caso de que no tenga espacio, tu próxima cita será la que ya tienes programada con su
            correspondiente costo.
          </li>
        </ul>
      </Content>
    </>
  ),
};

export default function Cancel() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const [session] = useRecoilState(selectedSession);

  // RRD
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ActionCancelSessionSchema),
  });

  const getCancelationStatusQuery = useQuery(
    ['getCancelationStatus', token, session?.id],
    () => getCancelationStatus(token, session?.id),
    {
      enabled: !!session?.id,
    },
  );

  const createCancelMutation = useMutation(
    (data) => createSessionCancelTicket(token, session.id, data),
    {
      onSuccess: async () => {
        toast.success('La sesión se cancelo con exito.');
        navigate('/app/sessions/detail/tickets');
      },
      onError: () => toast.error('Algo salió mal.'),
    },
  );

  const onSubmit = ({ reason }) => {
    createCancelMutation.mutate({ reason });
  };

  const cancelType = getCancelationStatusQuery?.data?.cancelation_type;
  const content = contentByCancelType[`${cancelType}`];

  return (
    <div>
      <Title className="text-lg text-red-500 leading-6 mt-2 lg:mt-0">Cancelar</Title>
      <br />
      {getCancelationStatusQuery.isLoading ? (
        <div className="flex justify-center w-full">
          <Loader className="h-12 w-12 text-primary" />
        </div>
      ) : null}
      {getCancelationStatusQuery.isSuccess ? (
        <>
          <Content className="text-md text-gray-500 leading-5">{content}</Content>
          <br />
          <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-6">
            <TextArea
              id="reason"
              type="text"
              register={register}
              registerConfig={{
                required: { value: true, message: 'Los comentarios son requeridos' },
              }}
              withLabel
              textLabel="Razón de cancelación"
              error={errors.reason?.message}
            />
            <p className="mt-4 text-sm text-gray-600">
              Para confirar, escriba en el campo de texto <i>Apruebo cancelar la cita</i>
            </p>
            <div className="md:mt-0 md:col-span-2">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  id="confirm"
                  type="text"
                  register={register}
                  registerConfig={{ required: true }}
                  withLabel
                  error={errors.confirm?.message}
                />
              </div>
            </div>
            <Button type="submit" className="text-white bg-red-500 rounded-md">
              Cancelar
            </Button>
          </form>
          <Button
            onClick={() => navigate('/app/sessions/detail')}
            className="mt-4 text-primary border border-gray-100 rounded-md"
          >
            Cerrar
          </Button>
        </>
      ) : null}
    </div>
  );
}
